<template>
  <div class="w-full">

    <vx-card>
      <div id="data-list-list-view" class="data-list-container">
        <vs-table ref="table" :max-items="itemsPerPage" :data="Model">
          <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
            <div class="flex flex-wrap-reverse items-center">
              <span v-if="queriedItems > 0" class="mr-2 text-xl">{{ $t("Total") }} :
              </span>
              <span v-if="queriedItems > 0" class="text-primary text-xl">
                {{ queriedItems }}
              </span>
            </div>
          </div>

          <template slot="thead">
            <vs-th>{{ $t("Patient") }}</vs-th>
            <vs-th>{{ $t("Date") }}</vs-th>
            <vs-th>{{ $t("Time") }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody :data="item" :key="indextr" v-for="(item, indextr) in data">
              <vs-tr class="cursor-pointer hover:text-primary">
                <vs-td>
                  <p v-if="item.Patient" class="product-name font-medium truncate">

                    {{ item.Patient.Name }}
                  </p>
                </vs-td>
                <vs-td>
                  <p class="product-category">
                    {{ item.DoctorSession.DateFormatted }}
                  </p>
                </vs-td>
                <vs-td>
                  <p class="product-category">
                    {{ item.DoctorSession.DateFrom.slice(11, 16) }}
                  </p>
                </vs-td>

              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </vx-card>
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <b-button type="border" color="danger" @click="$router.go(-1)">{{
            $t("Back")
          }}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { domain } from "@/gloabelConstant.js";
import modulePatient from "@/store/Patient/modulePatient.js";
import { BAvatar, BBadge, BButton, BCard, BCol, BDropdown, BDropdownItem, BFormInput, BLink, BMedia, BPagination, BRow, BTable, BTooltip } from 'bootstrap-vue';

export default {
  data() {
    return {
      itemsPerPage: 10,
      paginationPageNumber: 1,
      paginationMaxNumber: 5,
      baseURL: domain,
      Model: [],
    };
  },
  components: {
    BAvatar, BBadge, BButton, BCard, BCol, BDropdown, BDropdownItem, BFormInput, BLink, BMedia, BPagination, BRow, BTable, BTooltip
  },
  methods: {
    ConvertDate(date) {
      debugger;
      var ms = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var temp = new Date(date);
      return (
        temp.getDate() + " " + ms[temp.getMonth()] + " " + temp.getFullYear()
      );
    },
  },
  created() {
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }

    debugger;

    var curr = new Date(); // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6

    var firstday = new Date(curr.setDate(first)).toUTCString();
    var lastday = new Date(curr.setDate(last)).toUTCString();

    var Obj = {
      doctorID: this.$store.state.AppActiveUser.Doctor.ID,
      DateFrom: firstday,
      DateTo: lastday,
    };
    Obj.NotIncludeStatusIds = [3, 5] //cancel,completed
    Obj.NotIncludePaymentStatusIds = [1, 3] //Pending,canceled
    this.$store
      .dispatch("patientList/SearchPatientReservationSessions", Obj)
      .then((res) => {
        if (res.status == 200) {
          this.Model = res.data.Data;

        }
      })
      .catch((err) => {
        window.showError();
        window.showError(err.response.data.message);
      });
  },
};
</script>
<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      >span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 10px;
          width: fit-content;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>