
<template >
  <!-- v-if="$vs.rtl" -->
  <div class="w-full">
    <b-row>
      <b-col cols="12" md="4">
        <b-card
          :img-src="
            require('@/assets/images/patientHome/RepeatMedicalOnlineConsultations.png')
          "
          img-alt="Card image cap"
          img-top
          no-body
        >
          <p style="text-align: center; color: black">
            {{ $t("YourPatientsHome") }} 
          </p>
          <b-button
            @click="getYourPaient()"
            variant="primary"
            style="width: 100%"
          >
            {{ $t("YourPatients") }}
          </b-button>
        </b-card>
      </b-col>
      <b-col cols="12" md="4">
        <b-card
          :img-src="
            require('@/assets/images/patientHome/YourMedicalReports.png')
          "
          img-alt="Card image cap"
          img-top
          no-body
        >
          <p style="text-align: center; color: black">
            {{ $t("OnlineAppointmentHome") }}
          </p>
          <b-button
            @click="showOnlineAppointMent"
            variant="primary"
            style="width: 100%"
          >
            {{ $t("OnlineAppointment") }}
          </b-button>
        </b-card>
      </b-col>
      <b-col cols="12" md="4">
        <b-card
          :img-src="require('@/assets/images/patientHome/PendingDocument.png')"
          img-alt="Card image cap"
          img-top
          no-body
        >
          <p style="text-align: center; color: black">
            {{ $t("addYourAvailabilityHome") }}
          </p>
          <b-button
            @click="addYourAvailability()"
            variant="primary"
            style="width: 100%"
          >
            {{ $t("addYourAvailability") }}
          </b-button>
        </b-card>
      </b-col>

      <b-col cols="12" md="4">
        <b-card
          :img-src="
            require('@/assets/images/patientHome/PatientPaymentPending.png')
          "
          img-alt="Card image cap"
          img-top
          no-body
        >
          <p style="text-align: center; color: black">
            {{ $t("editYourAvailabilityHome") }}
          </p>
          <b-button
            @click="editYourAvailability()"
            variant="primary"
            style="width: 100%"
          >
            {{ $t("editYourAvailability") }}
          </b-button>
        </b-card>
      </b-col>

      <b-col cols="12" md="4">
        <b-card
          :img-src="
            require('@/assets/images/patientHome/SurgeriesTreatments.png')
          "
          img-alt="Card image cap"
          img-top
          no-body
        >
          <p style="text-align: center; color: black">
            {{ $t("PendingTaskHome") }}
          </p>
          <b-button
            @click="getPendingTask()"
            variant="primary"
            style="width: 100%"
          >
            {{ $t("PendingTask") }}
          </b-button>
        </b-card>
      </b-col>

      <b-col cols="12" md="4">
        <b-card
          :img-src="require('@/assets/images/patientHome/Packages.png')"
          img-alt="Card image cap"
          img-top
          no-body
        >
          <p style="text-align: center; color: black">
            {{ $t("thisWeekOnlineAppointmentsHome") }}
          </p>
          <b-button
          @click="$router.push({ name:'DoctorWeeklyAppointments' })"
            variant="primary"
            style="width: 100%"
          >
            {{ $t("thisWeekOnlineAppointments") }}
          </b-button>
        </b-card>
      </b-col>
      <b-col cols="12" md="4" v-if="$store.state.AppActiveUser.Doctor.InHospital">
      </b-col>

      <b-col cols="12" md="4" v-if="$store.state.AppActiveUser.Doctor.InHospital">
        <b-card
          :img-src="
            require('@/assets/images/patientHome/YourOnlineConsultations.png')
          "
          img-alt="Card image cap"
          img-top
          no-body
        >
          <p style="text-align: center; color: black">
            {{ $t("SurgeriesTreatmentsHome") }}
          </p>
          <b-button
            @click="SurgeriesTreatments()"
            variant="primary"
            style="width: 100%"
          >
            {{ $t("SurgeriesTreatments") }}
          </b-button>
        </b-card>
      </b-col>


    </b-row>
    <vs-popup
      title=""
      :active.sync="showDoctorWeeklyAppointment"
    >
      <DoctorWeeklyAppointments 
        v-if="showDoctorWeeklyAppointment"
        @closePop="showDoctorWeeklyAppointment=false"
      ></DoctorWeeklyAppointments>
    </vs-popup>
  </div>
</template>

<script>

import modulePatient from "@/store/Patient/modulePatient.js";
import DoctorWeeklyAppointments from "@/views/doctor/Appointment/DoctorWeeklyAppointments.vue";
import {
BButton,
BCard,
BCardBody,
BCardFooter,
BCardHeader,
BCardText,
BCol,
BImg,
BLink,
BRow
} from "bootstrap-vue";

export default {
  components: {
    DoctorWeeklyAppointments,
    BButton,
BCard,
BCardBody,
BCardFooter,
BCardHeader,
BCardText,
BCol,
BImg,
BLink,
BRow
    // vSelect
    //showOnlineAppointmentCom,
  },
  data() {
    return {
      search:{},
      showDoctorWeeklyAppointment:false,
      doctorDoc: {},
      // showOnlineAppointmentfield: false,
      propBG1: {
        backgroundImage: `url(${require("@/assets/images/doctor/card1.png")}) `,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      },
      propBG2: {
        backgroundImage: `url(${require("@/assets/images/doctor/card2.png")})`,
      
         backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      },
      arrowIcon: {
        backgroundImage: `url(${require("@/assets/images/doctor/arrowIcon.svg")})`,
        backgroundRepeat: "no-repeat"
      },
    };
  },

  methods: {
  editYourAvailability(){
 this.$router.push({ name: "DoctorEditAvailability"});
    },
    addYourAvailability(){
 this.$router.push({ name: "DoctorAddAvailability"});
    },

    getPendingTask() {
      //this.doctorDoc.DoctorID = this.$store.state.AppActiveUser.Doctor.ID;
      debugger;
      //this.showOnlineAppointmentfield=true;
      this.$router.push({
        name: "pendingTaskDoctor",
        params: { ID: this.$store.state.AppActiveUser.Doctor.ID },
      });
    },
       SurgeriesTreatments() {
      this.$router.push({ name:"ReservedSurgery" });
    },
    showOnlineAppointMent() {
      this.$router.push({
        name: "onlineAppointment",
        params: { ID: this.$store.state.AppActiveUser.Doctor.ID },
      });
    },
    getYourPaient() {
      debugger;
      var id = this.$store.state.AppActiveUser.Doctor.ID;
      this.$router.push({ name: "YourPatient", params: { ID: id } });
    },
    addNewData() {
      this.$router.push({ name: "PatientEdit" });
    },
    editData(ID) {
      this.$router.push({ name: "PatientEdit", params: { ID: ID } });
    },

    deleteData() {
      debugger;
      this.$vs.loading();
      this.$store
        .dispatch("patientList/DeletePatient", this.rowDataForDelete.ID)
        .then(() => {
          this.searchData();
          this.$vs.loading.close();
          window.showDeleteSuccess();
        })
        .catch((err) => {
          if (err.response.status == 400) {
            this.$vs.loading.close();
            window.showDeleteFailed(err.response.data.message);
          } else {
            this.$vs.loading.close();
            window.showDeleteFailed("");
          }
        });
    },

    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("DeleteConfirmText"),
        accept: this.deleteData,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },
    searchData() {
      this.$store
        .dispatch("patientList/SearchPatients", this.search)
        .then(() => {
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
  },

  created() {
    if (!modulePatient.isRegistered) {
      // this.$vs.loading();
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
   
    this.searchData();
  },
  mounted() {
    this.isMounted = true;
  },
  computed:{
     windowWidth() {
      return this.$store.state.windowWidth;
    },
  }
};
</script>
<style scoped>
#doctorHome  .backgroundShadow {
 background-color: transparent;
  box-shadow: rgb(0 0 0 / 20%) 5px 5px 15px;
  box-shadow: rgb(0 0 0 / 0%) 5px 5px 15px;
}


#doctorHome .vs-button {
  margin-bottom: 20px !important;
}
#doctorHome .text-sm {
  font-size: 0.95rem !important;
  font-weight: 600;
}
#doctorHome h3 {
  font-weight: bold;

  font-size: 1.7rem !important;
}
</style>
